import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Nav, Table } from 'react-bootstrap'

import Tags from '../components/tags'
import TransactionsCreationModal from '../components/transactions-creation-modal'
import fetchResources from '../utils/fetch-resources'
import formatCurrency from '../utils/format-currency'

class Transactions extends Component {
  state = {
    showTranscationsCreationModal: false
  }

  componentDidMount() {
    fetchResources('transactions')
  }

  toggleTransactionsCreationModal() {
    this.setState({
      showTransactionsCreationModal: !this.state.showTransactionsCreationModal
    })
  }

  render() {
    return (
      <div id='application.transactions'>
        <Button
          className='btn-round float-right'
          variant='no-outline-secondary'
          onClick={() => this.toggleTransactionsCreationModal()}
          id='application.transactions.showTransactionsCreationModalButton'
        >
          <i className='material-icons'>add</i>
        </Button>
        <TransactionsCreationModal
          showTransactionsCreationModal={
            this.state.showTransactionsCreationModal
          }
          toggleTransactionsCreationModal={this.toggleTransactionsCreationModal.bind(
            this
          )}
        />
        <Table hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Account</th>
              <th>Amount</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {this.props.transactions.map(transaction => (
              <tr key={transaction.id}>
                <th>
                  <Nav.Link
                    as={Link}
                    to={
                      '/entities/' +
                      transaction.entity +
                      '/books/' +
                      transaction.book +
                      '/accounts/' +
                      transaction.account +
                      '/transactions/' +
                      transaction.id
                    }
                  >
                    {transaction.id}
                  </Nav.Link>
                </th>
                <td>{transaction.date.substring(0, 10)}</td>
                <td>{transaction.account}</td>
                <td>
                  {formatCurrency(transaction.currency, transaction.amount)}
                </td>
                <td>
                  <Tags tags={transaction.tags} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    transactions: state.default.transactions
  }
}

export default connect(mapStateToProps)(Transactions)
