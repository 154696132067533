import React, { Component } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import cookie from 'react-cookies'
import { connect } from 'react-redux'

import createResource from '../utils/create-resource'
import handleInputChange from '../utils/handle-input-change'
import fetchResources from '../utils/fetch-resources'

class SignIn extends Component {
  state = {
    username: '',
    password: '',
    environment:
      /* istanbul ignore next */ cookie.load('environment') === 'live'
        ? 'live'
        : 'test'
  }

  onSubmit(submitEvent) {
    submitEvent.preventDefault()

    const creationParams = [
      { key: 'email', value: this.state.email },
      { key: 'password', value: this.state.password },
      { key: 'environment', value: this.state.environment }
    ]
    createResource(this.props.services.api + '/session', creationParams, () => {
      fetchResources('session')
    })
  }

  render() {
    return (
      <div className='main-container fullscreen' id='signIn'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={5} lg={6} md={7}>
              <div className='text-center'>
                <h1 className='h2'>Welcome back!</h1>
                <p className='lead'>Log in to your account to continue</p>
                <Form onSubmit={submitEvent => this.onSubmit(submitEvent)}>
                  <Form.Group>
                    <Form.Control
                      id='signIn.emailInput'
                      type='email'
                      name='email'
                      onChange={handleInputChange.bind(this)}
                      placeholder='Email address'
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      id='signIn.passwordInput'
                      onChange={handleInputChange.bind(this)}
                      type='password'
                      name='password'
                      placeholder='password'
                    />
                    <Form.Text className='text-muted'>
                      <span
                        id='signIn.signUpToggle'
                        onClick={() => this.props.toggleSignIn()}
                        style={{ cursor: 'pointer' }}
                      >
                        Need to register for an account?
                      </span>
                    </Form.Text>
                  </Form.Group>
                  <Button
                    className='btn-lg btn-block btn-primary'
                    id='signIn.signInButton'
                    type='submit'
                  >
                    Log in
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { services: state.default.services }
}

export default connect(mapStateToProps)(SignIn)
