export default function(resource, params, onSuccess) {
  const formData = new FormData()
  params.forEach(param => {
    formData.append(param.key, param.value)
  })

  fetch(resource, {
    method: 'POST',
    credentials: 'include',
    body: formData
  }).then(response => {
    /* istanbul ignore next */
    if (response.status === 200) {
      onSuccess()
    }
  })
}
