import React, { Component } from 'react'
import { Badge } from 'react-bootstrap'

class Tags extends Component {
  render() {
    return (
      <div>
        {Object.keys(this.props.tags).map((tag, index) => (
          <div key={index}>
            <Badge variant='secondary' pill>
              {tag + ': ' + this.props.tags[tag]}
            </Badge>
            <br />
          </div>
        ))}
      </div>
    )
  }
}

export default Tags
