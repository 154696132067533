import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'

import createResource from '../utils/create-resource'
import fetchResources from '../utils/fetch-resources'
import handleInputChange from '../utils/handle-input-change'

class EntityCreationModal extends Component {
  state = {}

  createEntity() {
    const creationParams = []
    const optionalParams = ['id', 'name', 'default']
    for (let i = 0; i < optionalParams.length; i++) {
      const key = optionalParams[i]
      const value = this.state[key]
      if (value != null) {
        creationParams.push({ key: key, value: value })
      }
    }
    createResource(
      this.props.services.api + '/entities',
      creationParams,
      () => {
        fetchResources('entities')
        this.props.toggleEntityCreationModal()
      }
    )
  }

  render() {
    return (
      <Modal
        id='application.entities.entityCreation'
        show={this.props.showEntityCreationModal}
        onHide={this.props.toggleEntityCreationModal}
      >
        <Modal.Header>
          <Modal.Title className='h5'>Add entity</Modal.Title>
          <Button
            className='close'
            onClick={this.props.toggleEntityCreationModal}
            id='application.entities.entityCreation.closeButton'
          >
            <i className='material-icons'>close</i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='row align-items-center'>
            <Form.Label className='col-3'>ID</Form.Label>
            <Form.Control
              id='application.entities.entityCreation.idInput'
              className='col'
              type='text'
              name='id'
              onChange={handleInputChange.bind(this)}
              placeholder='Optional entity ID'
            />
          </Form.Group>
          <Form.Group className='row align-items-center'>
            <Form.Label className='col-3'>Name</Form.Label>
            <Form.Control
              id='application.entities.entityCreation.nameInput'
              className='col'
              type='text'
              name='name'
              onChange={handleInputChange.bind(this)}
              placeholder='Optional entity name'
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              id='application.entities.entityCreation.defaultInput'
              name='default'
              type='checkbox'
              label='Set as the default entity?'
              onChange={handleInputChange.bind(this)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => this.createEntity()}
            id='application.entities.entityCreation.submitButton'
          >
            Add entity
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    services: state.default.services
  }
}

export default connect(mapStateToProps)(EntityCreationModal)
