/* istanbul ignore file */
export default function() {
  const currentDate = new Date()
  const startDate = new Date()
  startDate.setYear(currentDate.getFullYear() - 1)

  const commonState = {
    accounts: [],
    accountIDs: [],
    balanceSheet: {
      aggregations: [],
      currency: 'USD',
      title: '',
      unclassifiedAccounts: []
    },
    books: [],
    bookIDs: [],
    categoricals: {
      currency: [],
      title: []
    },
    entities: [],
    entityIDs: [],
    group: {
      apiKeys: [],
      automaticallyCreateAccounts: false,
      consolidationCurrency: 'USD',
      name: '',
      users: []
    },
    mappings: {
      currencyMinorUnitCountLookup: { JPY: 0, USD: 2 }
    },
    profitAndLossStatement: {
      aggregations: [],
      amount: 0,
      currency: 'USD',
      title: '',
      unclassifiedTransactions: []
    },
    services: { api: '' },
    session: { isSignedIn: false, environment: 'live' },
    stagedTransactions: [],
    transactions: [],
    user: { email: '' }
  }

  switch (process.env.NODE_ENV) {
    case 'development':
      return {
        ...commonState,
        services: { api: 'http://api.prefabc.localhost:30000' }
      }
    case 'test':
      return {
        ...commonState,
        services: { api: '' }
      }
    case 'production':
      return {
        ...commonState,
        services: { api: 'https://api.prefabc.com' }
      }
    default:
      throw new Error('Environment not known: ' + process.env.NODE_ENV)
  }
}
