import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import fetchResources from '../utils/fetch-resources'
import updateResource from '../utils/update-resource'

class EnvironmentToggle extends Component {
  toggleEnvironment() {
    const updatedEnvironment =
      this.props.session.environment === 'live' ? 'test' : 'live'

    updateResource(
      this.props.services.api + '/session',
      [{ key: 'environment', value: updatedEnvironment }],
      () => {
        fetchResources('session')
      }
    )
  }

  render() {
    return (
      <Button 
        id='application.environmentToggle'
        variant='primary'
        onClick={() => this.toggleEnvironment()}
      >
        This is {this.props.session.environment} data
      </Button>
    )
  }
}

const mapStateToProps = state => {
  return {
    services: state.default.services,
    session: state.default.session
  }
}

export default connect(mapStateToProps)(EnvironmentToggle)
