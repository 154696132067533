export function setAccountsState(value) {
  return { type: 'SET_ACCOUNTS', value: value }
}

export function setBalanceSheetState(state) {
  return { type: 'SET_BALANCE_SHEET_STATE', value: state }
}

export function setBooksState(value) {
  return { type: 'SET_BOOKS', value: value }
}

export function setEntitiesState(value) {
  return { type: 'SET_ENTITIES', value: value }
}

export function setGroupState(group) {
  return { type: 'SET_GROUP_STATE', value: group }
}

export function setInitialState(state) {
  return { type: 'SET_INITIAL_STATE', value: state }
}

export function setProfitAndLossStatementState(state) {
  return { type: 'SET_PROFIT_AND_LOSS_STATEMENT_STATE', value: state }
}

export function setSearchState(value) {
  return { type: 'SET_SEARCH_STATE', value: value }
}

export function setSessionStateToSignedIn(value) {
  return {
    type: 'SET_SESSION_STATE',
    value: { isSignedIn: true, environment: value.environment }
  }
}

export function setSessionStateToSignedOut() {
  return { type: 'SET_SESSION_STATE', value: { isSignedIn: false } }
}

export function setTransactionsState(value) {
  return { type: 'SET_TRANSACTIONS', value: value }
}

export function setUserState(value) {
  return { type: 'SET_USER', value: value }
}
