import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'

import createResource from '../utils/create-resource'
import fetchResources from '../utils/fetch-resources'
import handleInputChange from '../utils/handle-input-change'

class AccountCreationModal extends Component {
  state = {}

  componentDidMount() {
    fetchResources('books')
  }

  createAccount() {
    const book = this.props.books[this.state.bookIndex]
    const creationParams = []
    const optionalParams = ['id', 'name']
    for (let i = 0; i < optionalParams.length; i++) {
      const key = optionalParams[i]
      const value = this.state[key]
      if (value != null) {
        creationParams.push({ key: key, value: value })
      }
    }
    createResource(
      this.props.services.api +
        '/entities/' +
        book.entity +
        '/books/' +
        book.id +
        '/accounts',
      creationParams,
      () => {
        fetchResources('accounts')
        this.props.toggleAccountCreationModal()
      }
    )
  }

  render() {
    return (
      <Modal
        id='application.accounts.accountCreation'
        show={this.props.showAccountCreationModal}
        onHide={this.props.toggleAccountCreationModal}
      >
        <Modal.Header>
          <Modal.Title className='h5'>Add account</Modal.Title>
          <Button
            className='close'
            onClick={this.props.toggleAccountCreationModal}
            id='application.accounts.accountCreation.closeButton'
          >
            <i className='material-icons'>close</i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='row align-items-center'>
            <Form.Label className='col-3'>Book</Form.Label>
            <Form.Control
              as='select'
              id='application.accounts.accountCreation.bookIndexInput'
              name='bookIndex'
              onChange={handleInputChange.bind(this)}
              className='col'
              defaultValue='placeholder'
            >
              <option value='placeholder' disabled hidden>
                Book
              </option>
              {this.props.books.map((book, index) => {
                return (
                  <option key={book.id} value={index}>
                    {book.id}
                  </option>
                )
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group className='row align-items-center'>
            <Form.Label className='col-3'>ID</Form.Label>
            <Form.Control
              id='application.accounts.accountCreation.idInput'
              className='col'
              type='text'
              name='id'
              onChange={handleInputChange.bind(this)}
              placeholder='Optional account ID'
            />
          </Form.Group>
          <Form.Group className='row align-items-center'>
            <Form.Label className='col-3'>Name</Form.Label>
            <Form.Control
              id='application.accounts.accountCreation.nameInput'
              className='col'
              type='text'
              name='name'
              onChange={handleInputChange.bind(this)}
              placeholder='Optional account name'
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => this.createAccount()}
            id='application.accounts.accountCreation.submitButton'
          >
            Add account
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    books: state.default.books,
    services: state.default.services
  }
}

export default connect(mapStateToProps)(AccountCreationModal)
