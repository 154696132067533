import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Nav, Table } from 'react-bootstrap'

import AccountCreationModal from '../components/account-creation-modal'
import Tags from '../components/tags'
import fetchResources from '../utils/fetch-resources'
import formatCurrency from '../utils/format-currency'

class Accounts extends Component {
  state = {
    showAccountCreationModal: false
  }

  componentDidMount() {
    fetchResources('accounts')
  }

  toggleAccountCreationModal() {
    this.setState({
      showAccountCreationModal: !this.state.showAccountCreationModal
    })
  }

  render() {
    return (
      <div id='application.accounts'>
        <Button
          className='btn-round float-right'
          variant='no-outline-secondary'
          onClick={() => this.toggleAccountCreationModal()}
          id='application.accounts.showAccountCreationModalButton'
        >
          <i className='material-icons'>add</i>
        </Button>
        <AccountCreationModal
          showAccountCreationModal={this.state.showAccountCreationModal}
          toggleAccountCreationModal={this.toggleAccountCreationModal.bind(
            this
          )}
        />
        <Table hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Balance</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {this.props.accounts.map(account => (
              <tr key={account.id}>
                <th>
                  <Nav.Link
                    as={Link}
                    to={
                      '/entities/' +
                      account.entity +
                      '/books/' +
                      account.book +
                      '/accounts/' +
                      account.id
                    }
                  >
                    {account.id}
                  </Nav.Link>
                </th>
                <td>{account.name}</td>
                <td>{formatCurrency(account.currency, account.balance)}</td>
                <td>
                  <Tags tags={account.tags} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    accounts: state.default.accounts,
    environment: state.default.environment
  }
}

export default connect(mapStateToProps)(Accounts)
