import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Badge, Button, Nav, Table } from 'react-bootstrap'

import EntityCreationModal from '../components/entity-creation-modal'
import Tags from '../components/tags'
import fetchResources from '../utils/fetch-resources'

class Entities extends Component {
  state = {
    showEntityCreationModal: false
  }

  componentDidMount() {
    fetchResources('entities')
  }

  toggleEntityCreationModal() {
    this.setState({
      showEntityCreationModal: !this.state.showEntityCreationModal
    })
  }

  render() {
    return (
      <div id='application.entities'>
        <Button
          className='btn-round float-right'
          variant='no-outline-secondary'
          onClick={() => this.toggleEntityCreationModal()}
          id='application.entities.showEntityCreationModalButton'
        >
          <i className='material-icons'>add</i>
        </Button>
        <EntityCreationModal
          showEntityCreationModal={this.state.showEntityCreationModal}
          toggleEntityCreationModal={this.toggleEntityCreationModal.bind(this)}
        />
        <Table hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {this.props.entities.map(entity => (
              <tr key={entity.id}>
                <th>
                  <Nav.Link as={Link} to={'/entities/' + entity.id}>
                    {entity.id}
                    {entity.default ? (
                      <Badge variant='secondary'>Default</Badge>
                    ) : (
                      ''
                    )}
                  </Nav.Link>
                </th>
                <td>{entity.name}</td>
                <td>
                  <Tags tags={entity.tags} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    entities: state.default.entities,
    environment: state.default.environment
  }
}

export default connect(mapStateToProps)(Entities)
