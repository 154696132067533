import * as actionCreators from '../actions/action-creator'
import { store } from '../utils/create-store'

export default function(resource, params = {}) {
  let action, path
  switch (resource) {
    case 'accounts':
      path = '/accounts'
      action = parsedResponse => {
        store.dispatch(actionCreators.setAccountsState(parsedResponse))
      }
      break
    case 'balanceSheet':
      path = '/balance-sheet'
      action = parsedResponse => {
        store.dispatch(actionCreators.setBalanceSheetState(parsedResponse))
      }
      break
    case 'books':
      path = '/books'
      action = parsedResponse => {
        store.dispatch(actionCreators.setBooksState(parsedResponse))
      }
      break
    case 'entities':
      path = '/entities'
      action = parsedResponse => {
        store.dispatch(actionCreators.setEntitiesState(parsedResponse))
      }
      break
    case 'group':
      path = '/group'
      action = parsedResponse => {
        store.dispatch(actionCreators.setGroupState(parsedResponse))
      }
      break
    case 'initialState':
      path = '/initial-state'
      action = parsedResponse => {
        store.dispatch(actionCreators.setInitialState(parsedResponse))
      }
      break
    case 'profitAndLossStatement':
      path = '/profit-and-loss-statement'
      action = parsedResponse => {
        store.dispatch(
          actionCreators.setProfitAndLossStatementState(parsedResponse)
        )
      }
      break
    case 'search':
      path = '/search'
      action = parsedResponse => {
        store.dispatch(actionCreators.setSearchState(parsedResponse))
      }
      break
    case 'session':
      path = '/session'
      action = parsedResponse => {
        store.dispatch(actionCreators.setSessionStateToSignedIn(parsedResponse))
      }
      break
    case 'transactions':
      path = '/transactions'
      action = parsedResponse => {
        store.dispatch(actionCreators.setTransactionsState(parsedResponse))
      }
      break
    case 'user':
      path = '/user'
      action = parsedResponse => {
        store.dispatch(actionCreators.setUserState(parsedResponse))
      }
      break
    /* istanbul ignore next */
    default:
      throw new Error()
  }

  fetch(store.getState().default.services.api + path, {
    method: 'GET',
    credentials: 'include'
  })
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json()
        case 401:
          store.dispatch(actionCreators.setSessionStateToSignedOut())
          break
        default:
          throw new Error()
      }
    })
    .then(parsedResponse => action(parsedResponse))
    .catch(error => {
      /* istanbul ignore next */
      console.log(
        'There has been a problem fetching resource.',
        resource,
        error
      )
    })
}
