import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Authentication from '../containers/authentication'
import '../index.css'
import { store } from '../utils/create-store'
import fetchResources from '../utils/fetch-resources'

class Root extends Component {
  componentDidMount() {
    fetchResources('initialState')
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Authentication />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default Root
