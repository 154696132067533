import React, { Component } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import createResource from '../utils/create-resource'
import fetchResources from '../utils/fetch-resources'
import handleInputChange from '../utils/handle-input-change'

class TransactionsCreationModal extends Component {
  state = {
    stagedTransactions: []
  }

  componentDidMount() {
    fetchResources('accounts')
    fetchResources('books')
  }

  stageTransaction(submitEvent) {
    submitEvent.preventDefault()

    this.setState({
      staged_transactions: this.state.stagedTransactions.push({
        accountID: submitEvent.target.accountID.value,
        amount: submitEvent.target.amount.value,
        id: submitEvent.target.id.value
      })
    })
  }

  createTransactions() {
    const book = this.props.books[this.state.bookIndex]
    const creationParams = [{ key: 'date', value: this.state.date }]
    for (let i = 0; i < this.state.stagedTransactions.length; i++) {
      const stagedTransaction = this.state.stagedTransactions[i]
      creationParams.push({
        key: 'transactions[][account]',
        value: stagedTransaction.accountID
      })
      creationParams.push({
        key: 'transactions[][amount]',
        value: stagedTransaction.amount
      })
      if (stagedTransaction.id !== '') {
        creationParams.push({
          key: 'transactions[][id]',
          value: stagedTransaction.id
        })
      }
    }
    createResource(
      this.props.services.api +
        '/entities/' +
        book.entity +
        '/books/' +
        book.id +
        '/transactions',
      creationParams,
      () => {
        fetchResources('transactions')
        this.props.toggleTransactionsCreationModal()
      }
    )
  }

  render() {
    return (
      <Modal
        id='application.transactions.transactionsCreation'
        show={this.props.showTransactionsCreationModal}
        onHide={this.props.toggleTransactionsCreationModal}
      >
        <Modal.Header>
          <Modal.Title className='h5'>Add transactions</Modal.Title>
          <Button
            className='close'
            onClick={this.props.toggleTransactionsCreationModal}
            id='application.transactions.transactionsCreation.closeButton'
          >
            <i className='material-icons'>close</i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form 
            id='application.transactions.transactionsCreation.stageTranasctionForm'
            onSubmit={submitEvent => this.stageTransaction(submitEvent)}
          >
            <Form.Group className='row align-items-center'>
              <Form.Label className='col-3'>Account</Form.Label>
              <Form.Control
                as='select'
                id='application.transactions.transactionsCreation.stageTranasctionAccountIDInput'
                name='accountID'
                className='col'
                defaultValue='placeholder'
              >
                <option value='placeholder' disabled hidden>
                  Account
                </option>
                {this.props.accounts.map(account => (
                  <option key={account.id} value={account.id}>
                    {account.name || account.id}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className='row align-items-center'>
              <Form.Label className='col-3'>ID</Form.Label>
              <Form.Control
                id='application.transactions.transactionsCreation.stageTranasctionIDInput'
                className='col'
                type='text'
                name='id'
                placeholder='Optional transaction ID'
              />
            </Form.Group>
            <Form.Group className='row align-items-center'>
              <Form.Label className='col-3'>Amount</Form.Label>
              <Form.Control
                id='application.transactions.transactionsCreation.stageTranasctionAmountInput'
                className='col'
                type='number'
                name='amount'
              />
            </Form.Group>
            <div className='text-right'>
              <Button
                variant='outline-secondary'
                type='submit'
                id='application.transactions.transactionsCreation.stageTransactionButton'
              >
                Stage transaction
              </Button>
            </div>
          </Form>
          <hr />
          <Form.Group className='row align-items-center'>
            <Form.Label className='col-3'>Book</Form.Label>
            <Form.Control
              as='select'
              id='application.transactions.transactionsCreation.bookIndexInput'
              name='bookIndex'
              onChange={handleInputChange.bind(this)}
              className='col'
              defaultValue='placeholder'
            >
              <option value='placeholder' disabled hidden>
                Book
              </option>
              {this.props.books.map((book, index) => {
                return (
                  <option key={book.id} value={index}>
                    {book.id}
                  </option>
                )
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group className='row align-items-center'>
            <Form.Label className='col-3'>Date</Form.Label>
            <Form.Control
              id='application.transactions.transactionsCreation.dateInput'
              className='col'
              type='date'
              name='date'
              onChange={handleInputChange.bind(this)}
            />
          </Form.Group>
          <h6>Staged tranasctions</h6>
          <Table hover responsive size='sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Account</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {this.state.stagedTransactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.id}</td>
                  <td>{transaction.accountID}</td>
                  <td>{transaction.amount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => this.createTransactions()}
            id='application.transactions.transactionsCreation.submitButton'
          >
            Add transactions
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    accounts: state.default.accounts,
    books: state.default.books,
    services: state.default.services
  }
}

export default connect(mapStateToProps)(TransactionsCreationModal)
