import cookie from 'react-cookies'

import defaultState from '../utils/default-state'

export default function appSettingReducers(state = defaultState(), action) {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return { ...state, accounts: action.value }
    case 'SET_BALANCE_SHEET_STATE':
      return { ...state, balanceSheet: action.value }
    case 'SET_BOOKS':
      return { ...state, books: action.value }
    case 'SET_ENTITIES':
      return { ...state, entities: action.value }
    case 'SET_GROUP_STATE':
      return { ...state, group: action.value }
    case 'SET_INITIAL_STATE':
      return { ...state, ...action.value }
    case 'SET_PROFIT_AND_LOSS_STATEMENT_STATE':
      return { ...state, profitAndLossStatement: action.value }
    case 'SET_SESSION_STATE':
      cookie.save('environment', action.value.environment, { path: '/' })
      return { ...state, session: action.value }
    case 'SET_SEARCH_STATE':
      return { ...state, search: action.value }
    case 'SET_TRANSACTIONS':
      return { ...state, transactions: action.value }
    case 'SET_USER':
      return { ...state, user: action.value }
    default:
      return state
  }
}
