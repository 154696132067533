import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

class SearchResults extends Component {
  render() {
    return (
      <Table id='application.searchResults'>
        <thead>
          <tr>
            <th>Class</th>
            <th>ID</th>
          </tr>
        </thead>
        <tbody></tbody>
      </Table>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    search: state.default.search
  }
}

export default connect(mapStateToProps)(SearchResults)
