import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Application from '../containers/application'
import SignIn from '../containers/sign-in'
import SignUp from '../containers/sign-up'
import fetchResources from '../utils/fetch-resources'

class Authentication extends Component {
  state = {
    signIn: true,
  }

  componentDidMount() {
    fetchResources('session')
  }

  toggleSignIn() {
    this.setState({ signIn: !this.state.signIn })
  }

  render() {
    if (this.props.session.isSignedIn) {
      return <Application />
    } else {
      if (this.state.signIn) {
        return <SignIn toggleSignIn={this.toggleSignIn.bind(this)} />
      } else {
        return <SignUp toggleSignIn={this.toggleSignIn.bind(this)} />
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    application: state.default.application,
    session: state.default.session
  }
}

export default withRouter(connect(mapStateToProps)(Authentication))
