import React, { Component } from 'react'
import { Button, Form, InputGroup, Navbar } from 'react-bootstrap'
import { connect } from 'react-redux'

import createResource from '../utils/create-resource'
import handleInputChange from '../utils/handle-input-change'

class Feedback extends Component {
  constructor(props) {
    super(props)

    const prompts = [
      'You should really fix...',
      "Huh, it's pretty broken that...",
      "Gah, it's frustrating that...",
      "Huh, it's pretty cute that..."
    ]
    this.state = {
      feedback: null,
      feedbackSent: false,
      prompt: prompts[Math.floor(Math.random() * prompts.length)]
    }
  }

  createFeedback(submitEvent) {
    submitEvent.preventDefault()
    createResource(
      this.props.services.api + '/feedback',
      [
        { key: 'prompt', value: this.state.prompt },
        { key: 'feedback', value: this.state.feedback }
      ],
      () => {
        this.setState({ feedbackSent: true })
      }
    )
  }

  render() {
    return (
      <Navbar color='faded' fixed='bottom' hidden={this.state.feedbackSent}>
        <Form
          id='application.feedback.form'
          className='ml-auto'
          onSubmit={submitEvent => this.createFeedback(submitEvent)}
        >
          <InputGroup size='sm'>
            <Form.Control
              id='application.feedback.feedbackInput'
              name='feedback'
              placeholder={this.state.prompt}
              style={{ width: '250px' }}
              onChange={handleInputChange.bind(this)}
            />
            <InputGroup.Append>
              <Button variant='secondary'>Send feedback</Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      </Navbar>
    )
  }
}

const mapStateToProps = state => {
  return {
    services: state.default.services
  }
}

export default connect(mapStateToProps)(Feedback)
