import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router'

import Accounts from '../components/accounts'
import BalanceSheet from '../components/balance-sheet'
import Books from '../components/books'
import Entities from '../components/entities'
import ProfitAndLossStatement from '../components/profit-and-loss-statement'
import SearchResults from '../components/search-results'
import Transactions from '../components/transactions'

class Content extends Component {
  constructor() {
    super()
    ReactGA.initialize('UA-104518031-2', {
      testMode: process.env.NODE_ENV === 'test'
    })
  }

  render() {
    ReactGA.pageview(this.props.location.pathname)

    return (
      <Switch>
        <Route exact path='/' component={BalanceSheet} />
        <Route exact path='/accounts' component={Accounts} />
        <Route exact path='/balance-sheet' component={BalanceSheet} />
        <Route exact path='/books' component={Books} />
        <Route exact path='/entities' component={Entities} />
        <Route
          exact
          path='/profit-and-loss-statement'
          component={ProfitAndLossStatement}
        />
        <Route exact path='/search' component={SearchResults} />
        <Route exact path='/transactions' component={Transactions} />
      </Switch>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redirectToSearch: state.default.redirectToSearch
  }
}

export default withRouter(connect(mapStateToProps)(Content))
