import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Badge, Button, Nav, Table } from 'react-bootstrap'

import BookCreationModal from '../components/book-creation-modal'
import Tags from '../components/tags'
import nameFromMapping from '../utils/name-from-mapping'
import fetchResources from '../utils/fetch-resources'

class Books extends Component {
  state = {
    showBookCreationModal: false
  }

  componentDidMount() {
    fetchResources('books')
  }

  toggleBookCreationModal() {
    this.setState({
      showBookCreationModal: !this.state.showBookCreationModal
    })
  }

  render() {
    return (
      <div id='application.books'>
        <Button
          className='btn-round float-right'
          variant='no-outline-secondary'
          onClick={() => this.toggleBookCreationModal()}
          id='application.books.showBookCreationModalButton'
        >
          <i className='material-icons'>add</i>
        </Button>
        <BookCreationModal
          showBookCreationModal={this.state.showBookCreationModal}
          toggleBookCreationModal={this.toggleBookCreationModal.bind(this)}
        />
        <Table hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Currency</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {this.props.books.map(book => (
              <tr key={book.id}>
                <th>
                  <Nav.Link
                    as={Link}
                    to={'/entities/' + book.entity + '/books/' + book.id}
                  >
                    {book.id}
                    {book.default ? (
                      <Badge variant='secondary'>Default</Badge>
                    ) : (
                      ''
                    )}
                  </Nav.Link>
                </th>
                <td>
                  {nameFromMapping(
                    this.props.categoricals.currency,
                    book.currency
                  )}
                </td>
                <td>
                  <Tags tags={book.tags} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    books: state.default.books,
    categoricals: state.default.categoricals,
    environment: state.default.environment
  }
}

export default connect(mapStateToProps)(Books)
