import React, { Component } from 'react'
import { Button, Nav, Navbar } from 'react-bootstrap'
import Gravatar from 'react-gravatar'
import { connect } from 'react-redux'

import GroupSettingsModal from '../components/group-settings-modal'

class Header extends Component {
  state = {
    showGroupSettingsModal: false
  }

  toggleGroupSettingsModal() {
    this.setState({
      showGroupSettingsModal: !this.state.showGroupSettingsModal
    })
  }

  render() {
    return (
      <Navbar bg='light'>
        <Navbar.Brand href='/'>Prefab C</Navbar.Brand>
        <Nav className='ml-auto'>
          <Nav.Item>
            <Gravatar
              email={this.props.user.email}
              style={{ marginRight: '10px' }}
              size={35}
              default='mm'
            />
          </Nav.Item>
          <Nav.Item>
            <Button
              id='application.showGroupSettingsModalButton'   
              variant='outline-primary'
              onClick={this.toggleGroupSettingsModal.bind(this)}
            >
              Group settings
            </Button>
            <GroupSettingsModal
              showGroupSettingsModal={this.state.showGroupSettingsModal}
              toggleGroupSettingsModal={this.toggleGroupSettingsModal.bind(
                this
              )}
            />
          </Nav.Item>
        </Nav>
      </Navbar>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.default.user
  }
}

export default connect(mapStateToProps)(Header)
