import React, { Component } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import fetchResources from '../utils/fetch-resources'

class SearchBar extends Component {
  search(submitEvent) {
    console.log('submitting!!')
    submitEvent.preventDefault()
    fetchResources('search')

    this.props.history.push('search')
  }

  render() {
    return (
      <Form 
        id='application.searchBar.form'
        onSubmit={submitEvent => this.search(submitEvent)}
      >
        <InputGroup>
          <FormControl
            id='application.searchBar.queryInput'
            name='query'
            placeholder='Search by ID, name, tag...'
          />
          <InputGroup.Append>
            <Button 
              id='application.searchBar.submitButton'
              variant='outline-secondary'
              type='submit'
            >
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default withRouter(connect(mapStateToProps)(SearchBar))
