import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import fetchResources from '../utils/fetch-resources'
import formatCurrency from '../utils/format-currency'

class BalanceSheet extends Component {
  componentDidMount() {
    fetchResources('balanceSheet')
  }

  render() {
    const balanceSheet = this.props.balanceSheet

    return (
      <Table id='application.balanceSheet'>
        <thead>
          <tr>
            <th colSpan='2'>{balanceSheet.title}</th>
          </tr>
        </thead>
        <tbody>
          {balanceSheet.aggregations.map(resource => (
            <BalanceSheetRow
              key={resource.title}
              resource={resource}
              currency={balanceSheet.currency}
            />
          ))}
          {balanceSheet.unclassifiedAccounts.map(resource => (
            <BalanceSheetRow
              key={resource.title}
              resource={resource}
              currency={balanceSheet.currency}
            />
          ))}
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    balanceSheet: state.default.balanceSheet,
    environment: state.default.environment
  }
}

export default connect(mapStateToProps)(BalanceSheet)

class BalanceSheetRow extends Component {
  render() {
    const reportRow = this.props.resource

    return (
      <tr>
        <th scope='row'>{reportRow.title}</th>
        <td className='text-right'>
          {formatCurrency(this.props.currency, reportRow.amount, false)}
        </td>
      </tr>
    )
  }
}
