import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Col, Container, Row } from 'react-bootstrap'

import Content from '../containers/content'
import Header from '../components/header'
import EnvironmentToggle from '../components/environment-toggle'
import Feedback from '../components/feedback'
import Footer from '../components/footer'
import Navigation from '../components/navigation'
import SearchBar from '../components/search-bar'
import fetchResources from '../utils/fetch-resources'

class Application extends Component {
  componentDidMount() {
    fetchResources('user')
    fetchResources('group')
  }

  render() {
    return (
      <div id='application'>
        <Header />
        <Container>
          <Row>
            <Col xs='3'>
              <EnvironmentToggle />
            </Col>
            <Col xs='9'>
              <SearchBar />
            </Col>
          </Row>
          <Row>
            <Col xs='3'>
              <Navigation />
            </Col>
            <Col className='content' xs='9'>
              <Content />
            </Col>
          </Row>
          <Row>
            <Col className='text-center' xs='12'>
              <Footer />
            </Col>
          </Row>
        </Container>
        <Feedback />
      </div>
    )
  }
}

export default withRouter(Application)
