import React, { Component } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

import createResource from '../utils/create-resource'
import handleInputChange from '../utils/handle-input-change'
import fetchResources from '../utils/fetch-resources'

class SignUp extends Component {
  state = {}

  onSubmit(submitEvent) {
    submitEvent.preventDefault()

    const creationParams = [
      { key: 'email', value: this.state.email },
      { key: 'password', value: this.state.password }
    ]
    createResource(
      this.props.services.api + '/register',
      creationParams,
      () => {
        fetchResources('session')
      }
    )
  }

  render() {
    return (
      <div className='main-container fullscreen' id='signUp'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={5} lg={6} md={7}>
              <div className='text-center'>
                <h1 className='h2'>Welcome!</h1>
                <p className='lead'>Create an account to continue</p>
                <Form onSubmit={submitEvent => this.onSubmit(submitEvent)}>
                  <Form.Group>
                    <Form.Control
                      id='signUp.emailInput'
                      type='email'
                      name='email'
                      onChange={handleInputChange.bind(this)}
                      placeholder='Email address'
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      id='signUp.passwordInput'
                      onChange={handleInputChange.bind(this)}
                      type='password'
                      name='password'
                      placeholder='password'
                    />
                    <Form.Text className='text-muted'>
                      <span
                        id='signUp.signInToggle'
                        onClick={() => this.props.toggleSignIn()}
                        style={{ cursor: 'pointer' }}
                      >
                        Already have an account?
                      </span>
                    </Form.Text>
                  </Form.Group>
                  <Button
                    className='btn-lg btn-block btn-primary'
                    id='signUp.signUpButton'
                    type='submit'
                  >
                    Sign up
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { services: state.default.services }
}

export default connect(mapStateToProps)(SignUp)
