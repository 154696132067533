import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class Navigation extends Component {
  render() {
    return (
      <Navbar className='flex-column align-items-start'>
        <Nav.Link as={Link} to='/entities'>
          Entities
        </Nav.Link>
        <Nav.Link as={Link} to='/books'>
          Books
        </Nav.Link>
        <Nav.Link as={Link} to='/accounts'>
          Accounts
        </Nav.Link>
        <Nav.Link as={Link} to='/transactions'>
          Transactions
        </Nav.Link>
        <hr />
        <Nav.Link as={Link} to='/balance-sheet'>
          Balance sheet
        </Nav.Link>
        <Nav.Link as={Link} to='/profit-and-loss-statement'>
          Profit and loss statement
        </Nav.Link>
      </Navbar>
    )
  }
}

export default Navigation
