import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import { Button, Form, Modal, Nav, Table, Tab } from 'react-bootstrap'

import fetchResources from '../utils/fetch-resources'
import handleInputChange from '../utils/handle-input-change'
import nameFromMapping from '../utils/name-from-mapping'
import updateResource from '../utils/update-resource'

class GroupSettingsModal extends Component {
  state = {}

  updateGroup(submitEvent) {
    submitEvent.preventDefault()
    const updateParams = []
    const optionalParams = [
      'name',
      'consolidationCurrency',
      'automaticallyCreateAccounts'
    ]
    for (let i = 0; i < optionalParams.length; i++) {
      let key = optionalParams[i]
      let value = this.state['group.' + key]
      if (value != null) {
        updateParams.push({ key: key, value: value })
      }
    }
    updateResource(this.props.services.api + '/group', updateParams, () => {
      fetchResources('group')
    })
  }

  render() {
    return (
      <Modal
        id='application.groupSettings'      
        show={this.props.showGroupSettingsModal}
        onHide={this.props.toggleGroupSettingsModal}
      >
        <Modal.Header toggle={this.toggleSettingsModal}>
          <Modal.Title>Group settings</Modal.Title>
          <Button
            id='application.groupSettings.closeButton'
            className='close'
            onClick={this.props.toggleGroupSettingsModal}
          >
            <i className='material-icons'>close</i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey='group'>
            <Nav className='flex-row' justify variant='tabs'>
              <Nav.Item
                id='application.groupSettings.groupTabLink'
              >
                <Nav.Link eventKey='group'>Group</Nav.Link>
              </Nav.Item>
              <Nav.Item
                id='application.groupSettings.keysTabLink'              
              >
                <Nav.Link eventKey='keys'>Keys</Nav.Link>
              </Nav.Item>
              <Nav.Item
                id='application.groupSettings.teamTabLink'              
              >              
                <Nav.Link eventKey='team'>Team</Nav.Link>
              </Nav.Item>
              <Nav.Item
                id='application.groupSettings.excelTabLink'              
              >
                <Nav.Link eventKey='excel'>Excel</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey='group'>
                <Form 
                  id='application.groupSettings.group.form'
                  onSubmit={submitEvent => this.updateGroup(submitEvent)}
                >
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      id='application.groupSettings.group.nameInput'
                      name='group.name'
                      defaultValue={this.props.group.name}
                      onChange={handleInputChange.bind(this)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Consolidation currency</Form.Label>
                    <Form.Control
                      id='application.groupSettings.group.consolidationCurrencyInput'                    
                      name='group.consolidationCurrency'
                      as='select'
                      defaultValue={this.props.group.consolidationCurrency}
                      onChange={handleInputChange.bind(this)}
                    >
                      {this.props.categoricals.currency.map(currency => {
                        return (
                          <option key={currency.value} value={currency.value}>
                            {currency.name}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      id='application.groupSettings.group.automaticallyCreateAccountsInput'                     
                      name='group.automaticallyCreateAccounts'
                      defaultChecked={
                        this.props.group.automaticallyCreateAccounts
                      }
                      type='checkbox'
                      label='Automatically create accounts'
                      onChange={handleInputChange.bind(this)}
                    />
                  </Form.Group>
                  <div className='text-center'>
                    <Button 
                      id='application.groupSettings.group.submitButton'
                      type='submit'
                    >
                      Update group settings
                    </Button>
                  </div>
                </Form>
              </Tab.Pane>
              <Tab.Pane eventKey='keys'>
                <Table>
                  <thead>
                    <tr>
                      <th>Environment</th>
                      <th>Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.group.apiKeys.map(apiKey => (
                      <APIKeyRow key={apiKey.id} apiKey={apiKey} />
                    ))}
                  </tbody>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey='team'>
                <Table responsive>
                  <tbody>
                    {this.props.group.users.map(user => (
                      <tr key={user.email}>
                        <td>{user.email}</td>
                        <td>
                          {nameFromMapping(
                            this.props.categoricals.title,
                            user.title
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey='excel'>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td>
                        <a
                          href={
                            this.props.services.api +
                            '/downloads/excel-bindings'
                          }
                        >
                          Excel app
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    categoricals: state.default.categoricals,
    group: state.default.group,
    services: state.default.services
  }
}

export default connect(mapStateToProps)(GroupSettingsModal)

class APIKeyRow extends Component {
  state = { copied: false }

  render() {
    const apiKey = this.props.apiKey

    return (
      <tr>
        <th scope='row'>{apiKey.environment}</th>
        <td>
          {apiKey.id}
          <CopyToClipboard
            className='float-right'
            onCopy={/* istanbul ignore next */ () => this.setState({ copied: true })}
            text={apiKey.id}
          >
            <Button variant='outline-secondary' size='sm'>
              {/* istanbul ignore next */ this.state.copied ? 'Copied' : 'Copy'}
            </Button>
          </CopyToClipboard>
        </td>
      </tr>
    )
  }
}
