import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import fetchResources from '../utils/fetch-resources'
import formatCurrency from '../utils/format-currency'

class ProfitAndLossStatement extends Component {
  componentDidMount() {
    fetchResources('profitAndLossStatement')
  }

  render() {
    const profitAndLossStatement = this.props.profitAndLossStatement

    return (
      <Table id='application.profitAndLossStatement'>
        <thead>
          <tr>
            <th>{profitAndLossStatement.title}</th>
          </tr>
        </thead>
        <tbody>
          {profitAndLossStatement.aggregations.map(resource => (
            <ProfitAndLossStatementRow
              key={resource.title}
              resource={resource}
              currency={profitAndLossStatement.currency}
            />
          ))}
          {profitAndLossStatement.unclassifiedTransactions.map(resource => (
            <ProfitAndLossStatementRow
              key={resource.title}
              resource={resource}
              currency={profitAndLossStatement.currency}
            />
          ))}
          <tr>
            <th scope='row'>Total</th>
            <td className='text-right'>
              {formatCurrency(profitAndLossStatement.currency, profitAndLossStatement.amount, false)}
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profitAndLossStatement: state.default.profitAndLossStatement
  }
}

export default connect(mapStateToProps)(ProfitAndLossStatement)

class ProfitAndLossStatementRow extends Component {
  render() {
    const reportRow = this.props.resource

    return (
      <tr>
        <th scope='row'>{reportRow.title}</th>
        <td className='text-right'>
          {formatCurrency(this.props.currency, reportRow.amount, false)}
        </td>
      </tr>
    )
  }
}
